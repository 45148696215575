import selectAncestor from './select-ancestor';

document.addEventListener('DOMContentLoaded', () => {
  document.body.addEventListener('click', (e) => {
    if (e.target.hasAttribute('data-mobile-grid-hide')) {
      const container = selectAncestor(e.target, '.mobile-grid');

      container.classList.remove('mobile-grid--details');
      container.classList.add('mobile-grid--list');
    }
  }, true);
});
