import selectAncestor from './select-ancestor';

document.addEventListener('DOMContentLoaded', () => {
  // initialize
  const allComponents = document.querySelectorAll('[data-view-scanner]');
  allComponents.forEach(c => {
    const vsi = c.querySelector('[data-view-scanner-item]');
    if (!!vsi) { vsi.classList.add('show-view-scanner-item') }
  });

  // handle functionality
  document.querySelector('body').addEventListener('click', (e) => {
    if (e.target.hasAttribute('data-view-scanner-to')) {
      const next = parseInt(e.target.getAttribute('data-view-scanner-to'), 10);

      const container = selectAncestor(e.target, '[data-view-scanner]');
      if (container && container.hasAttribute('data-view-scanner')) {
        const size = parseInt(container.getAttribute('data-view-scanner'), 10);

        let nextView;
        if (next >= size) {
          nextView = container.querySelector('[data-view-scanner-item="0"]');
        } else if (next < 0) {
          nextView = container.querySelector(`[data-view-scanner-item="${size - 1}"]`);
        } else {
          nextView = container.querySelector(`[data-view-scanner-item="${next}"]`);
        }

        if (!!nextView) {
          const currView = selectAncestor(e.target, '[data-view-scanner-item]');
          currView.classList.remove('show-view-scanner-item');
          nextView.classList.add('show-view-scanner-item');
        }
      }
    }
  }, true);

  // side effect
  window.viewScannerSetView = (id, index) => {
    const container = document.getElementById(id);

    if (!!container) {
      const prevView = container.querySelector('.show-view-scanner-item');
      if (!!prevView) { prevView.classList.remove('show-view-scanner-item') }

      const newView = container.querySelector(`[data-view-scanner-item="${index}"]`);
      newView.classList.add('show-view-scanner-item');
    } else {
      console.error(`view scanner: id ${id} DNE`);
    }
  }
});
