document.addEventListener('DOMContentLoaded', () => {
  document.body.addEventListener('change', (e) => {
    if (e.target.classList.contains('tab-accordion-item__input')) {
      const tabAccordion = e.target.closest('.tab-accordion');
      const tabAccordionItem = e.target.closest('.tab-accordion-item');
      const tabAccordionMain = tabAccordion.querySelector('.tab-accordion__main');
      const tabAccordionContent = tabAccordionItem.querySelector('.tab-accordion-item__content');
      if (tabAccordionContent) {
        tabAccordionMain.innerHTML = tabAccordionContent.innerHTML;
      }
    }
  }, true);
});
