import selectAncestor from './select-ancestor';

function handleDropZoneEvent(event, handler) {
  document.querySelector('body').addEventListener(
    event,
    (e) => {
      const dropZone = selectAncestor(e.target, '.drop-zone-preview');
      if (!!dropZone) {
        e.preventDefault();
        e.stopPropagation();
        handler(dropZone, e);
      }
    },
  );
}

document.addEventListener('DOMContentLoaded', () => {
  // prevent browser from opening file
  console.log("content loaded");
  window.addEventListener('dragover', (e) => {
    e = e || event;
    e.preventDefault();
  },false);
  window.addEventListener('drop', (e) => {
    e = e || event;
    e.preventDefault();
  },false);

  // handle input usage
  document.querySelector('body').addEventListener(
    'change',
    (e) => {
      const dz = selectAncestor(e.target, '.drop-zone-preview');
      if (!!dz) {
        const files = [...e.target.files];
        if (files && files[files.length - 1]) {
          const imagePreview = dz.querySelector('img.drop-zone-preview__preview');
          if (imagePreview) {
            const url = URL.createObjectURL(files[files.length - 1]);
            imagePreview.setAttribute('src', url);
          }
          const textPreview = dz.querySelector('span.drop-zone-preview__preview');
          if (textPreview) {
            textPreview.innerText = files[files.length - 1].name;
          }
          dz.classList.add('drop-zone-preview--has-file');
        }
      }
    },
  );

  // handle drop zone usage
  handleDropZoneEvent('dragenter', dz => dz.classList.add('drop-zone-preview--highlight'));
  handleDropZoneEvent('dragover', dz => dz.classList.add('drop-zone-preview--highlight'));
  handleDropZoneEvent('dragleave', dz => dz.classList.remove('drop-zone-preview--highlight'));
  handleDropZoneEvent('drop', (dz, e) => {
    dz.classList.remove('drop-zone-preview--highlight');

    const files = [...e.dataTransfer.files];
    if (files && files[files.length - 1]) {
      const fileInput = dz.querySelector('.drop-zone-preview__input');

      if (fileInput && fileInput.getAttribute('accept').includes(files[files.length - 1].type)) {
        fileInput.files = e.dataTransfer.files;

        const imagePreview = dz.querySelector('img.drop-zone-preview__preview');
        if (imagePreview) {
          const url = URL.createObjectURL(files[files.length - 1]);
          imagePreview.setAttribute('src', url);
        }
        const textPreview = dz.querySelector('span.drop-zone-preview__preview');
        if (textPreview) {
          textPreview.innerText = files[files.length - 1].name;
        }

        dz.classList.add('drop-zone-preview--has-file');

        if (dz.hasAttribute('data-drop-zone-after-drop')) {
          trashSpaghettiCodeBecauseIAmLosingMyMind();
        }
      }
    }
  });

  document.querySelector('body').addEventListener('click', (e) => {
    if (e.target.hasAttribute('data-drop-zone-preview-reset')) {
      const formId = e.target.getAttribute('data-drop-zone-preview-reset');
      const form = document.getElementById(formId);
      const dz = form.querySelector('.drop-zone-preview');

      form.reset();

      const preview = dz.querySelector('.drop-zone-preview__preview');
      preview.setAttribute('src', '');
      dz.classList.remove('drop-zone-preview--has-file');
    }
  }, true);
});


function trashSpaghettiCodeBecauseIAmLosingMyMind() {
  const button = document.querySelector(`[data-challenge-step-number="0"] input.button`)
  button.disabled = (
    form.querySelector('[name="challenge[title]"]').value === ''
    || form.querySelector('[name=numberOfQuestions]').value === ''
    || (
      document.getElementById('challenge-video-reference-id').value === ''
      && (document.getElementById('challenge-video-upload').value === '')
      && form.querySelector('[name="challenge[image]"]').value === ''
      && (form.querySelector('[data-persistedImage]').src === '' || form.querySelector('[data-persistedImage]').src === window.location.href)
      )
    );
}
