/* renders a partial with attached handlers */
function createModal({
  id,                     // (required) id of modal container
  partial,                // (required) html string
  onDidPresent,           // callback when modal is rendered
  onDidDismiss,           // callback when modal is destroyed
  backdropDismiss = true, // dismiss modal when backdrop is clicked
  clearLegacyModals = false,
}) {
  const modalContainer = document.createElement('div');
  modalContainer.setAttribute('id', id);
  modalContainer.classList.add('modal-container');

  const modalDisplay = document.createElement('div');
  modalDisplay.classList.add('modal-container__modal-display');
  if (backdropDismiss) modalDisplay.setAttribute('data-close-modal', '');

  modalDisplay.innerHTML = partial;
  modalContainer.appendChild(modalDisplay);

  const presentModal = () => {
    document.body.appendChild(modalContainer);
    document.body.style.overflow = 'hidden';
    onDidPresent?.(modalContainer);
  }

  const dismissModal = () => {
    document.body.removeChild(modalContainer);
    document.body.style.overflow = 'auto';
    onDidDismiss?.(modalContainer);
  }

  /* handle [data-close-modal] */
  modalContainer.addEventListener('click', e => {
    if (e.target.hasAttribute('data-close-modal') || e.target.hasAttribute('data-local-modal')) {
      dismissModal();
    }
  }, false);

  /* handle esc key */
  document.addEventListener('keyup', (e) => {
    if (e.key === 'Escape') {
      dismissModal();
    }
  });

  /* handle back button */
  window.addEventListener('popstate', () => {
    dismissModal();
  });

  /* clear legacy modals */
  if (clearLegacyModals) {
    const localModalsContainer = document.getElementById('local-modals-container');
    [...localModalsContainer.children].forEach((elem) => {
      if (elem.classList.contains('modal-container--show')) {
        elem.classList.remove('modal-container--show');
      }
    });
  }

  /* if modal exists already, kill it */
  const possibleEle = document.getElementById(id);
  if (!!possibleEle) possibleEle.remove();

  presentModal();
}
window.createModal = createModal;
