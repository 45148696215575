import axios from 'axios';

document.addEventListener('DOMContentLoaded', () => {
  const checkboxes = document.getElementsByClassName('subscribe-to-conversation-emails-checkbox')

  for (let i = 0; i < checkboxes.length; i++){
    const checkbox = checkboxes[i];
    checkbox.addEventListener('change', (e) => {
      const optIn = e.target.checked;
      const url = `/email_opt_ins/opt_${optIn ? 'in' : 'out'}.json`
      const shopId = checkbox.getAttribute('data-shop-id');
      const csrf = document.querySelector('meta[name=csrf-token]').getAttribute('content');
      const headers = {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': csrf,
      };
      const data = {
        email_opt_in: {
          email: 'new_job_conversation_message',
          shop_id: shopId,
        },
      };
      const method = 'POST';
      axios({ url, method, headers, data })
        .then(function (response) {
          const checked = response.data.email_opt_ins.opted_in;
          checkbox.setAttribute('checked', checked);
        })
        .catch(function (error) {
          console.log(error);
        });
    })
  }
});

document.addEventListener('DOMContentLoaded', () => {
  const checkboxes = document.getElementsByClassName('subscribe-to-conversation-notifications-checkbox')

  for (let i = 0; i < checkboxes.length; i++){
    const checkbox = checkboxes[i];
    checkbox.addEventListener('change', (e) => {
      const optIn = e.target.checked;
      const url = `/conversation_notification_opt_ins/opt_${optIn ? 'in' : 'out'}.json`
      const shopId = checkbox.getAttribute('data-shop-id');
      const csrf = document.querySelector('meta[name=csrf-token]').getAttribute('content');
      const headers = {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': csrf,
      };
      const data = {
        conversation_notification_opt_in: {
          shop_id: shopId,
        },
      };
      const method = 'POST';
      axios({ url, method, headers, data })
        .then(function (response) {
          const checked = response.data.conversation_notification_opt_ins.opted_in;
          checkbox.setAttribute('checked', checked);
        })
        .catch(function (error) {
          console.log(error);
        });
    })
  }
});
