/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()

require("../detect-ie")

require("../utilities.js")
require("../application")
require("../presentable")
require("../present-tooltip")
require("../alerts")
require("../modals")
require("../mobile-sidebar")
require("../sortable")
require("../draggable")
require("../dropzone-min.js")
require("../drop-zone")
require("../drop-zone")
require("../drop-zone-preview")
require("../tab-accordion")
require("../view-scanner")
//require("../websockets")
require("../format-date")
require("../conversation-message-subscription")
require("../file-size")
require("../job-topbar-mobile-nav")
require("../videojs")
require("../timeago")
require("../redirect-events")
require("../xhr-events")
require("../scroll-down-onload")
require("../segmented-control")
require("../tooltip")
require("../toast-myshop")
require("../ajax")
require("../og-data-scraper")

require("../partner-offer-preview")
require("../compensation-details")
require("../school-course-add-btn")
require("../school-instructor-add-btn")
require("../topshop-profile-group-alert")
require("../topshops-profile-banner-preview")
require("../question-percent-viewer")
require("../photo-viewer")
require("../mobile-grid")

require("../topshops-profile/about-us-form")
require("../topshops-profile/faq-nav")
require("../topshops-profile/segment")
require("../topshops-profile/profile-form")

require("../topshops/carousel")

require("../myshop-profile-form")
window.Uppy = {
  uppy: require('@uppy/core'),
  dashboard: require('@uppy/dashboard'),
  tus: require('@uppy/tus')
}

window.tus = require("tus-js-client");

window.axios = require('axios');

window.QRCode = require("easyqrcodejs")

window.linkify = require('linkifyjs');
window.linkifyHtml = require('linkify-html');

window.FroalaEditor = require('../froala_editor.pkgd.min.js')

window.sortablejs = require('sortablejs');

window.FilePond = require('filepond');
window.FilePondPluginFileValidateSize = require('filepond-plugin-file-validate-size');

import Rails from "@rails/ujs";
window.Rails = Rails
