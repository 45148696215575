import axios from 'axios';

document.addEventListener('DOMContentLoaded', () => {
  function handleRequest(e, type) {
    if (e.target.hasAttribute(`data-xhr-${type}`)) {
      const csrf = document.querySelector('meta[name=csrf-token]').getAttribute('content');
      const headers = {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': csrf,
      };

      const url = e.target.getAttribute(`data-xhr-${type}`);
      const method = e.target.getAttribute('data-xhr-method') ?? 'get';
      const data = e.target.getAttribute('data-xhr-data') ?? null;
      const reload = e.target.getAttribute('data-xhr-reload') ?? false;
      if(e.target.getAttribute('data-xhr-as-js')){
        headers["accept"] = "application/javascript"
      }

      axios({
        headers,
        url,
        method,
        data,
      }).then((response) => {
        if(e.target.getAttribute('data-xhr-as-js')){
          eval(response.data)
        }
        if (reload) window.location.reload();
      });
    }
  }

  document.body.addEventListener('click', e => handleRequest(e, 'onclick'), true);
  document.body.addEventListener('change', e => handleRequest(e, 'onchange'), true);
});
