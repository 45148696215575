document.addEventListener('DOMContentLoaded', () => {
  let delay = 0;
  let dist  = 10;

  function positionAt(parent, tooltip, posHorizontal, posVertical) {
    var parentCoords = parent.getBoundingClientRect(), left, top;

    switch (posHorizontal) {
      case 'left':
        left = parseInt(parentCoords.left) - dist - tooltip.offsetWidth;
        if (parseInt(parentCoords.left) - tooltip.offsetWidth < 0) {
            left = dist;
        }
        break;
      case 'right':
        left = parentCoords.right + dist;
        if (parseInt(parentCoords.right) + tooltip.offsetWidth > document.documentElement.clientWidth) {
            left = document.documentElement.clientWidth - tooltip.offsetWidth - dist;
        }
        break;
      case 'center':
      default:
        if (parseInt(parentCoords.right) + tooltip.offsetWidth > document.documentElement.clientWidth) {
          left = document.documentElement.clientWidth - tooltip.offsetWidth - dist;
        } else {
          left = parseInt(parentCoords.left) + ((parent.offsetWidth - tooltip.offsetWidth) / 2);
        }
    }

    switch (posVertical) {
      case 'center':
        top = (parseInt(parentCoords.top) + parseInt(parentCoords.bottom)) / 2 - tooltip.offsetHeight / 2;
        break;
      case 'bottom':
        top = parseInt(parentCoords.bottom) + dist;
        break;
      case 'top':
      default:
        top = parseInt(parentCoords.top) - tooltip.offsetHeight - dist;
    }

    left = (left < 0) ? parseInt(parentCoords.left) : left;
    top  = (top < 0) ? parseInt(parentCoords.bottom) + dist : top;

    tooltip.style.left = left + 'px';
    tooltip.style.top  = top + scrollY + 'px';
  }

  function showTooltip(e) {
    const target = e.target.hasAttribute('data-tooltip') ? e.target : e.target.parentNode;

    if (target.hasAttribute('data-tooltip')) {
      let tooltip = document.createElement('div');
      tooltip.className = 'js-tooltip';
      tooltip.innerHTML = (target.getAttribute('data-tooltip') || '').trim();

      if (target.hasAttribute('data-tooltip-class')) {
        tooltip.className += ` ${target.getAttribute('data-tooltip-class')}`;
      }

      document.body.appendChild(tooltip);

      let pos = target.getAttribute('data-position') || 'center bottom';
      let posHorizontal = pos.split(' ')[0];
      let posVertical = pos.split(' ')[1];

      positionAt(target, tooltip, posHorizontal, posVertical);
    }
  }

  function hideTooltip(e) {
    const target = e.target.hasAttribute('data-tooltip') ? e.target : e.target.parentNode;

    if (target.hasAttribute('data-tooltip')) {
      console.log('mouseout')
      setTimeout(() => {
        document.body.removeChild(document.querySelector('.js-tooltip'));
      }, delay);
    }
  }

  const tooltips = document.body.querySelectorAll('[data-tooltip]');
  tooltips.forEach(tooltip => {
    tooltip.addEventListener('mouseover', showTooltip, false);
    tooltip.addEventListener('touchstart', showTooltip, false);
    tooltip.addEventListener('mouseout', hideTooltip, false);
    tooltip.addEventListener('touchend', hideTooltip, false);
  });
});
