import selectAncestor from './select-ancestor';

document.addEventListener('DOMContentLoaded', () => {
  document.body.addEventListener('change', (e) => {
    if (e.target.id.startsWith('show-hourly-equiv-checkbox')) {
      const section = selectAncestor(e.target, '.compensation-section');
      if (!!section) {
        const [min, max] = section.querySelectorAll('.compensation-pay-range input');

        const minHourly = ((min.value ?? 0) / 2080).toFixed(2);
        const maxHourly = ((max.value ?? 0) / 2080).toFixed(2);

        const preview = section.querySelector('.compensation-pay-range__hourly_equiv_preview');
        preview.innerText = (e.target.checked && minHourly && maxHourly) ? `($${minHourly} - $${maxHourly}/hr)` : '';
      }
    }
  }, true);

  document.body.addEventListener('input', (e) => {
    if (e.target.classList.contains('compensation-pay-range-input') && (e.target.id.endsWith('_pay_range_min') || e.target.id.endsWith('_pay_range_max'))) {
      const section = selectAncestor(e.target, '.compensation-section');
      if (!!section) {
        const checkbox = section.querySelector('[id^="show-hourly-equiv-checkbox"]');
        const [min, max] = section.querySelectorAll('.compensation-pay-range input');

        const minHourly = ((min.value ?? 0) / 2080).toFixed(2);
        const maxHourly = ((max.value ?? 0) / 2080).toFixed(2);

        const preview = section.querySelector('.compensation-pay-range__hourly_equiv_preview');
        preview.innerText = (checkbox.checked && minHourly && maxHourly) ? `($${minHourly} - $${maxHourly}/hr)` : '';
      }
    }
  }, true);

  document.body.addEventListener('change', (e) => {
    if (e.target.id.startsWith('show-hourly-equiv-checkbox')) {
      const section = selectAncestor(e.target, '.compensation-section');
      if (!!section) {
        const [min, max] = section.querySelectorAll('.compensation-pay-range input');

        const minHourly = ((min.value ?? 0) / 2080).toFixed(2);
        const maxHourly = ((max.value ?? 0) / 2080).toFixed(2);

        const preview = section.querySelector('.compensation-pay-range__hourly_equiv_preview');
        preview.innerText = (e.target.checked && minHourly && maxHourly) ? `($${minHourly} - $${maxHourly}/hr)` : '';
      }
    }
  }, true);

  document.body.addEventListener('input', (e) => {
    if (e.target.classList.contains('compensation-pay-range-input') && (e.target.id.endsWith('_pay_range_min') || e.target.id.endsWith('_pay_range_max'))) {
      const section = selectAncestor(e.target, '.compensation-section');
      if (!!section) {
        const checkbox = section.querySelector('[id^="show-hourly-equiv-checkbox"]');
        const [min, max] = section.querySelectorAll('.compensation-pay-range input');

        const minHourly = ((min.value ?? 0) / 2080).toFixed(2);
        const maxHourly = ((max.value ?? 0) / 2080).toFixed(2);

        const preview = section.querySelector('.compensation-pay-range__hourly_equiv_preview');
        preview.innerText = (checkbox.checked && minHourly && maxHourly) ? `($${minHourly} - $${maxHourly}/hr)` : '';
      }
    }
  }, true);
});
