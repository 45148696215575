import selectAncestor from './select-ancestor';

document.addEventListener('DOMContentLoaded', () => {
  const activeMenuClass = 'app-container__nav-menu--show-menu';
  const sidebar = document.getElementById('main-sidebar');

  const sidebarLink = document.getElementById('mobile-sidebar-link');
  if (!!sidebarLink) {
    sidebarLink.addEventListener('click', () => {
      sidebar.classList.toggle(activeMenuClass);
    });
  }

  document.querySelector('body').addEventListener('click', (e) => {
    const openSidebarTarget = selectAncestor(e.target, '[data-open-sidebar]');
    if (!!openSidebarTarget) {
      if (!sidebar.classList.contains(activeMenuClass)) {
        sidebar.classList.add(activeMenuClass);
      }
    }

    const closeSidebarTarget = selectAncestor(e.target, '[data-close-sidebar]');
    if (!!closeSidebarTarget) {
      if (sidebar.classList.contains(activeMenuClass)) {
        sidebar.classList.remove(activeMenuClass);
      }
    }
  });
});
