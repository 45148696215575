document.addEventListener('DOMContentLoaded', () => {
  if (!!document.querySelector('body.top_shop_profiles.show') || !!document.querySelector('body.schools.show')) {
    const showSection = sectionId => {
      const section = document.getElementById(sectionId);
      if (!!section) section.classList.remove('hide--mobile');
    }

    const hideSection = sectionId => {
      const section = document.getElementById(sectionId);
      if (!!section) section.classList.add('hide--mobile');
    }

    const showInfoSegment = () => {
      // hide
      hideSection('photos-section');
      hideSection('under-the-hood-section');
      hideSection('shop-talk-section');
      hideSection('local-giveaway-section');

      // show
      showSection('profile-section');
      showSection('shop-type-section');
      showSection('about-us-mobile-section');
      showSection('open-positions-section');
      showSection('get-in-touch-section');
      showSection('topshops-profile-checklist');
      showSection('mobile-approved-on-banner');
    }

    const showPhotosSegment = () => {
      // hide
      hideSection('profile-section');
      hideSection('shop-type-section');
      hideSection('about-us-mobile-section');
      hideSection('under-the-hood-section');
      hideSection('open-positions-section');
      hideSection('get-in-touch-section');
      hideSection('topshops-profile-checklist');
      hideSection('mobile-approved-on-banner');
      hideSection('shop-talk-section');
      hideSection('local-giveaway-section');

      // show
      showSection('photos-section');
    }

    const showHoodSegment = () => {
      // hide
      hideSection('profile-section');
      hideSection('shop-type-section');
      hideSection('about-us-mobile-section');
      hideSection('photos-section');
      hideSection('open-positions-section');
      hideSection('get-in-touch-section');
      hideSection('topshops-profile-checklist');
      hideSection('mobile-approved-on-banner');
      hideSection('shop-talk-section');
      hideSection('local-giveaway-section');

      // show
      showSection('under-the-hood-section');
    }

    const showVideosSegment = () => {
      // hide
      hideSection('profile-section');
      hideSection('shop-type-section');
      hideSection('about-us-mobile-section');
      hideSection('under-the-hood-section');
      hideSection('open-positions-section');
      hideSection('get-in-touch-section');
      hideSection('topshops-profile-checklist');
      hideSection('mobile-approved-on-banner');
      hideSection('photos-section');

      // show
      showSection('shop-talk-section');
      showSection('local-giveaway-section');
    }

    // initialize
    const hash = window.location.hash.slice(1);
    const infoSegment = document.getElementById('topshops_profile_segment-info');
    const hoodSegment = document.getElementById('topshops_profile_segment-hood');
    const photosSegment = document.getElementById('topshops_profile_segment-photos');
    const videosSegment = document.getElementById('topshops_profile_segment-videos');

    if (hash.includes('segment=photos')) {
      showPhotosSegment();
      photosSegment.click();
    } else if (hash.includes('segment=under-the-hood')) {
      showHoodSegment();
      hoodSegment.click();
    } else if (hash.includes('segment=shop-talk')) {
      showVideosSegment();
      videosSegment.click();
    } else {
      showInfoSegment();
      if(!!infoSegment){infoSegment.click();}
    }

    if (!!infoSegment) {
      infoSegment.addEventListener('change', () => {
        if (document.querySelector('input[name="topshops_profile_segment"]:checked').value === 'info') {
          showInfoSegment();
          const urlParams = (new URL(document.location)).searchParams.toString();
          window.history.replaceState(null, '', `${document.location.pathname}?${urlParams}#segment=info`);
        }
      });
    }

    if (!!photosSegment) {
      photosSegment.addEventListener('change', () => {
        if (document.querySelector('input[name="topshops_profile_segment"]:checked').value === 'photos') {
          showPhotosSegment();
          const urlParams = (new URL(document.location)).searchParams.toString();
          window.history.replaceState(null, '', `${document.location.pathname}?${urlParams}#segment=photos`);
        }
      });
    }

    if (!!hoodSegment) {
      hoodSegment.addEventListener('change', () => {
        if (document.querySelector('input[name="topshops_profile_segment"]:checked').value === 'hood') {
          showHoodSegment();
          const urlParams = (new URL(document.location)).searchParams.toString();
          window.history.replaceState(null, '', `${document.location.pathname}?${urlParams}#segment=under-the-hood`);
        }
      });
    }

    if (!!videosSegment) {
      videosSegment.addEventListener('change', () => {
        if (document.querySelector('input[name="topshops_profile_segment"]:checked').value === 'videos') {
          showVideosSegment();
          const urlParams = (new URL(document.location)).searchParams.toString();
          window.history.replaceState(null, '', `${document.location.pathname}?${urlParams}#segment=shop-talk`);
        }
      });
    }

  }
});
