import axios from 'axios';
import sanitizeHtml from 'sanitize-html';

document.addEventListener('DOMContentLoaded', () => {
  document.body.addEventListener('change', (e) => {
    if (e.target.hasAttribute('data-topshop-profile-group-alert')) {
      const { label, is_valid: isValid = true, action, method = 'patch' } = JSON.parse(e.target.getAttribute('data-topshop-profile-group-alert'));

      const alertWrapper = document.createElement('div');
      alertWrapper.classList.add('alert-wrapper');
      alertWrapper.setAttribute('data-topshop-profile-group-alert-close', e.target.id);

      alertWrapper.innerHTML = isValid ? `
        <div class="alert">
          <div class="alert__header">Please Confirm</div>
          <div class="alert__message">${
            e.target.checked
              ? `This will overwrite all info for the <strong>${sanitizeHtml(label)}</strong> section in all of the individual shop pages included in your group. `
              : `This will allow you to edit the <strong>${sanitizeHtml(label)}</strong> section on the individual shop's WrenchWay profile.`
          }</div>
          <div class="alert__buttons">
            <a data-topshop-profile-group-alert-close="${sanitizeHtml(e.target.id)}">Cancel</a>
            <a id="topshop-profile-group-alert-confirm" data-url="${action}" data-url-method="${method}">Confirm</a>
          </div>
        </div>
      ` : `
        <div class="alert">
          <div class="alert__header">Warning</div>
          <div class="alert__message">You must first enter text into this section before the <strong>${sanitizeHtml(label)}</strong> section can be enabled.</div>
          <div class="alert__buttons">
            <a data-topshop-profile-group-alert-close="${sanitizeHtml(e.target.id)}">Okay</a>
          </div>
        </div>
      `;

      document.body.appendChild(alertWrapper);
    }
  }, true);

  document.body.addEventListener('click', (e) => {
    if (e.target.id === 'topshop-profile-group-alert-confirm') {
      const url = e.target.getAttribute('data-url');
      const method = e.target.getAttribute('data-url-method');
      const csrf = document.querySelector('meta[name=csrf-token]').getAttribute('content');
      const headers = {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': csrf,
      };
      axios({ url, method, headers })
        .then(() => {
          const alerts = document.body.querySelectorAll('.alert-wrapper');
          alerts.forEach(alert => alert.parentNode.removeChild(alert));
        })
        .catch(err => console.error(err));
    }
  }, true);

  document.body.addEventListener('click', (e) => {
    if (e.target.hasAttribute('data-topshop-profile-group-alert-close')) {
      const alerts = document.body.querySelectorAll('.alert-wrapper');
      alerts.forEach(alert => alert.parentNode.removeChild(alert));

      const toggleId = e.target.getAttribute('data-topshop-profile-group-alert-close');
      const toggle = document.getElementById(toggleId);
      toggle.checked = !toggle.checked;
    }
  }, true);
});
