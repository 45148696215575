import selectAncestor from './select-ancestor';

function handleDropZoneEvent(event, handler) {
  document.querySelector('body').addEventListener(
    event,
    (e) => {
      const dropZone = selectAncestor(e.target, '.drop-zone');
      if (!!dropZone) {
        e.preventDefault();
        e.stopPropagation();
        handler(dropZone, e);
      }
    },
  );
}

document.addEventListener('DOMContentLoaded', () => {
  // prevent browser from opening file
  window.addEventListener('dragover', (e) => {
    e = e || event;
    e.preventDefault();
  },false);
  window.addEventListener('drop', (e) => {
    e = e || event;
    e.preventDefault();
  },false);

  // handle input usage
  document.querySelector('body').addEventListener(
    'change',
    (e) => {
      const dz = selectAncestor(e.target, '.drop-zone');
      if (!!dz) {
        const files = [...e.target.files];
        if (files && files[0]) {
          const fileNameContainer = dz.querySelector('.drop-zone__file-name');
          fileNameContainer.innerText = files[0].name;

          dz.classList.add('drop-zone--has-file');
        }
      }
    },
  );

  // handle drop zone usage
  handleDropZoneEvent('dragenter', dz => dz.classList.add('drop-zone--highlight'));
  handleDropZoneEvent('dragover', dz => dz.classList.add('drop-zone--highlight'));
  handleDropZoneEvent('dragleave', dz => dz.classList.remove('drop-zone--highlight'));
  handleDropZoneEvent('drop', (dz, e) => {
    dz.classList.remove('drop-zone--highlight');

    const files = [...e.dataTransfer.files];
    if (files && files[0]) {
      const fileNameContainer = dz.querySelector('.drop-zone__file-name');
      fileNameContainer.innerText = files[0].name;

      const fileInput = dz.querySelector('.drop-zone__input');
      fileInput.files = e.dataTransfer.files;

      dz.classList.add('drop-zone--has-file');
    }
  });
});
