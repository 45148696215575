import selectAncestor from './select-ancestor';

document.addEventListener('DOMContentLoaded', () => {
  document.body.addEventListener('click', (e) => {
    if (document.body.classList.contains('challenges') && document.body.classList.contains('index')) {
      if (e.target.hasAttribute('data-question-percent-back') || e.target.hasAttribute('data-question-percent-next')) {
        const container = selectAncestor(e.target, '[data-question-percent]');
        const index = parseInt(container.getAttribute('data-question-percent'), 10);
        const newIndex = index + (e.target.hasAttribute('data-question-percent-next') ? 1 : -1);

        const allComponents = document.querySelectorAll('[data-question-percent]');
        allComponents.forEach((c, i) => {
          if (i !== newIndex) c.classList.add('hide'); else c.classList.remove('hide');
        });
      }
    }
  }, true);
});
