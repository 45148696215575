window.addEventListener('load', ()=>{
  const dropdown = document.getElementById('job-navigation-bar-mobile-dropdown')
  if(dropdown){
    dropdown.addEventListener('input',e => navigate(e.target.value))
  }
})

function navigate(loc){
  document.querySelector(`.navigation-bar__options [data-navigation-link="${loc}"]`).click();
}
