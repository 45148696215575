function doKeepSearching(element) {
  return element && (element.classList && !element.classList.contains('modal')) && (element.classList && !element.classList.contains('app-container'));
}

export default function selectAncestor(element, selector) {
  if (!element) { return null; }

  if (selector.startsWith('.')) {
    do {
      if (element.classList && element.classList.contains(selector.slice(1))) {
        return element;
      }
      element = element.parentNode;
    } while (doKeepSearching(element));
  }
  else if (selector.startsWith('[') && selector.endsWith(']')) {
    do {
      if (element.hasAttribute && element.hasAttribute(selector.slice(1, -1))) {
        return element;
      }
      element = element.parentNode;
    } while (doKeepSearching(element));
  }
  return null;
}
