const { stringExists, isValidZipCode } = require("../validators")

document.addEventListener('DOMContentLoaded', () => {
  if (!!document.querySelector('body.top_shop_profiles.show') && !!document.querySelector('#topshop-profile-form')) {

    const form = document.getElementById('topshop-profile-form');
    const submit = form.querySelector('input[type="submit"]');

    const isMasterForm = !!document.getElementById('topshop_profile_is_master');

    const ids = isMasterForm ? [
      'shop_url',
    ] : [
      'shop_top_shop_profile_attributes_shop_name',
      'shop_url',
    ];

    let state = {};
    ids.forEach(id => state = {
      ...state,
      [id]: document.getElementById(id)?.value,
    });

    const handleSubmitButton = () => {
      submit.disabled = !!isMasterForm
    }

    handleSubmitButton();
    form.addEventListener('input', (event) => {
      const { id, value } = event.target;
      state = { ...state, [id]: value };
      handleSubmitButton();
    });

  }
});
