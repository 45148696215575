export const SHORT = 'short';
export const SHORT_YEAR = 'short_year';
export const MINI = 'mini';
export const FULL = 'full';


export const beginsAt = (dateStr) => {
  const date = new Date(dateStr);
  date.setDate(date.getDate() + 1);
  date.setHours(0, 0, 0, 0);
  return date.toString();
};

export const endsAt = (dateStr) => {
  const date = new Date(dateStr);
  date.setDate(date.getDate() + 1);
  date.setHours(23, 59, 59, 999);
  return date.toString();
};


/**
 * Add days to the a date
 */
export const addDays = (value, startDate) => {
  if (!value) return 'invalid';

  let now;
  if (startDate === undefined) {
    now = new Date();
  } else {
    now = new Date(startDate);
  }

  now.setDate((now.getDate() + parseInt(value.toString(), 10)) - 1);

  const dd = now.getDate();
  const mm = now.getMonth() + 1;
  const y = now.getFullYear();

  return `${mm}/${dd}/${y}`;
};


/**
 * Format time string.
 */
function formatTime(date) {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampmHours = hours % 12;

  const hourStr = ampmHours || 12;
  const minuteStr = minutes < 10 ? `0${minutes}` : minutes;
  const ampm = hours >= 12 ? 'PM' : 'AM';
  return `${hourStr}:${minuteStr} ${ampm}`;
}


/**
 * Return month as string.
 */
export const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const longMonthNames = [
'January', 'February', 'March', 'April',
'May', 'June', 'July', 'August', 'September',
'October', 'November', 'December'
];
export function getMonth(date, options={}) {
  return options.long?longMonthNames[date.getMonth()]:monthNames[date.getMonth()];
}
export function getMonthNameFor(month, options={}) {
  return options.long?longMonthNames[month]:monthNames[month];
}


/**
 * Return day as string.
 */
const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];
function getDay(dateStr) {
  const date = new Date(dateStr);
  return dayNames[date.getDay()];
}


/**
 * Return the start of a day for the given date.
 */
function startOfDay(dateStr) {
  const date = new Date(dateStr);
  date.setHours(0, 0, 0, 0);
  return date;
}


/**
 * Are the given dates in the same day?
 */
function isSameDay(leftDateStr) {
  return (rightDateStr) => {
    const leftDate = startOfDay(leftDateStr);
    const rightDate = startOfDay(rightDateStr);
    return leftDate.getTime() === rightDate.getTime();
  };
}


/**
 * Are the given dates in the same day?
 */
function withinDays(days) {
  return (dateStr) => {
    const tmpDate = new Date();
    const date = new Date(dateStr);
    tmpDate.setDate(tmpDate.getDate() - days);
    return date.getTime() >= tmpDate.getTime();
  };
}

const isOfThisWeek = withinDays(6);

/**
 * Formats the date string used for discussion items
 *
 * Today: "1:11 PM"
 * Less than a week: "Mon"
 * Over a week: "Aug 7"
 */
export function miniFormat(dateStr) {
  const isOfToday = isSameDay(new Date());
  const date = new Date(dateStr);

  if (isOfToday(dateStr)) {
    return formatTime(date);
  }
  if (isOfThisWeek(dateStr)) {
    return getDay(date);
  }
  return `${getMonth(date)} ${date.getDate()}`;
}

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const months = [
  'January', 'February', 'March',
  'April', 'May', 'June', 'July',
  'August', 'September', 'October',
  'November', 'December',
];
function addZero(i) {
  return (i < 10) ? `0${i}` : i;
}


/**
 * Formats the date string used for items in a collection.
 *
 * Today: "1:11 PM"
 * Any other time: "9/23/2020, 1:11 PM"
 */
export function fullFormat(dateStr) {
  const isOfToday = isSameDay(new Date());
  const date = new Date(dateStr);

  if (isOfToday(dateStr)) {
    return formatTime(date);
  }
  return `${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()}, ${formatTime(date)}`;
}

/**
 * Formats the date string used for items in a collection.
 *
 * Today: "1:11 PM"
 * Any other time: "9/23/2020"
 */
export function quickFormat(dateStr) {
  const isOfToday = isSameDay(new Date());
  const date = new Date(dateStr);

  if (isOfToday(dateStr)) {
    return formatTime(date);
  }
  return `${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()}`;
}

export function formatDate(date, format, divider="/") {
  let dateObj = date;
  if (!(date instanceof Date)) dateObj = new Date(date);

  const month = months[dateObj.getMonth()];
  const shortMonth = shortMonths[dateObj.getMonth()];
  const day = days[dateObj.getDay()];
  const dayOfMonth = dateObj.getDate();
  const year = dateObj.getFullYear();
  const hours = dateObj.getHours() % 12 ? dateObj.getHours() % 12 : 12;
  const ampm = dateObj.getHours() >= 12 ? 'PM' : 'AM';
  const minutes = addZero(dateObj.getMinutes());

  switch (format) {
  case MINI:
    return miniFormat(date);
  case SHORT:
    return `${dateObj.getMonth()+1}${divider}${dateObj.getDate()}${divider}${year}`;
  case SHORT_YEAR:
    return `${dateObj.getMonth()+1}${divider}${dateObj.getDate()}${divider}${year.toString().slice(-2)}`;
  case FULL:
    return `${shortMonth} ${dateObj.getDate()}, ${year}, ${hours}:${minutes} ${ampm}`;
  default:
    return `${day}, ${month} ${dayOfMonth}, ${year}`;
  }
}

export default formatDate;
