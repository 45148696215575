let currSectionName = null;

document.addEventListener('DOMContentLoaded', () => {
  document.body.addEventListener('click', (e) => {
    if (e.target.classList.contains('enlargeable-photo') && !e.target.hasAttribute("data-position")) {
      e.target.addEventListener('click', () => enlargePhoto({
        description: e.target.getAttribute("data-image-description"),
        loc: e.target.getAttribute("data-large-src"),
        position: 0,
        max: 1,
      }));
    } else if (e.target.classList.contains('enlargeable-photo')) {
      const shopImages = document.getElementsByClassName("enlargeable-photo");
      Array.from(shopImages).forEach(
        (el) => {
          el.addEventListener('click', () => enlargePhoto(enlargeParamsFromElement(el)))
        }
      )
    }
  }, true);

  const lightbox  = document.getElementById("photo-lightbox");

  if (lightbox) {
    const lightboxImage = document.getElementById("photo-lightbox-image");
    const nextButton = document.getElementById("photo-lightbox-next-button");
    const backButton = document.getElementById("photo-lightbox-back-button");
    const closeButton = document.getElementById("photo-lightbox-close-button");

    nextButton.addEventListener("click", changeImage('next'))
    backButton.addEventListener("click", changeImage('back'))
    closeButton.addEventListener("click", hideLightbox)
    setupTouchSwipe(lightboxImage);
  }

  document.addEventListener('keyup', (e) => {
    const plb = document.getElementById('photo-lightbox');
    if (!plb?.classList.contains('hide')) {
      if (e.key === 'Escape') {
        e.stopPropagation();
        hideLightbox();
      } else if (e.key === 'ArrowLeft') { // left arrow
        document.getElementById("photo-lightbox-back-button").click();
      }
      else if (e.key === 'ArrowRight') { // right arrow
        document.getElementById("photo-lightbox-next-button").click();
      }
    }
  }, true);
})

function setupTouchSwipe(imageElement){
  const state = { };
  imageElement.addEventListener('touchstart', initializeTouch(state), false);
  imageElement.addEventListener('touchend', completeTouch(state), false);
}

function initializeTouch(state){
  return function(e){
    state['start'] = e.touches[0].clientX;
  }
}

function completeTouch(state){
  return function(e){
    state['end'] = e.changedTouches[0].clientX;
    if(state['start'] > state['end']){
      changeImage('next')()
    }else{
      changeImage('back')()
    }
  }
}

function changeImage(direction){
  return ()=>{
    const photoLightBoxImage = document.getElementById("photo-lightbox-image");
    if (photoLightBoxImage.hasAttribute("data-position")) {
      const position = parseInt(photoLightBoxImage.getAttribute("data-position"));
      let images = document.getElementsByClassName("enlargeable-photo")

      if (!!currSectionName) {
        images = Array.from(images).filter(ele => ele.getAttribute('data-enlargeable-photo-name') === currSectionName)
      }

      let nextPosition = 0;
      switch(direction){
      case 'next':
        nextPosition = enlargeNextImage({position, images});
        break;
      case 'back':
        nextPosition = enlargePreviousImage({position, images});
        break;
      }
      enlargePhoto(enlargeParamsFromElement(images[nextPosition]))
    }
  }
}

function enlargeParamsFromElement(el){
  currSectionName = el.hasAttribute('data-enlargeable-photo-name') ? el.getAttribute('data-enlargeable-photo-name') : null;

  let images = document.getElementsByClassName("enlargeable-photo")

  if (!!currSectionName) {
    images = Array.from(images).filter(ele => ele.getAttribute('data-enlargeable-photo-name') === currSectionName)
  }

  return {
    name: currSectionName,
    description: el.getAttribute("data-image-description"),
    loc: el.getAttribute("data-large-src"),
    position: parseInt(el.getAttribute("data-position")),
    max: images.length,
  }
}

function enlargePreviousImage({position, images}){
  let nextPosition = position-1;
  if(nextPosition < 0) nextPosition = images.length-1;
  return nextPosition;
}

function enlargeNextImage({position, images}){
  let nextPosition = position+1;
  if(nextPosition == images.length) nextPosition = 0;
  return nextPosition;
}

function enlargePhoto({name, loc, position, max, description}){
  document.getElementById("photo-lightbox").classList.remove("hide");
  document.getElementById("photo-lightbox-image").setAttribute("src", loc);

  if (position === 0 && max === 1) {
    document.getElementById("photo-lightbox").classList.add('photo-lightbox--only');
  } else {
    document.getElementById("photo-lightbox-image").setAttribute("data-position", position);
  }

  document.getElementById("photo-lightbox-description").innerHTML=description;
  document.getElementById("photo-lightbox-position").innerHTML=(position+1);
  document.getElementById("photo-lightbox-maximum").innerHTML=(max);
}

function hideLightbox(){
  document.getElementById("photo-lightbox").classList.add("hide");
  currSectionName = null;
}
