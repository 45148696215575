const oneDay = 24 * 60 * 60 * 1000; // h * m * s * ms
const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

const diffDays = (date) => Math.round(Math.abs((Date.now() - date.getTime()) / oneDay));

export function getMonthName(dateStr = Date.now()) {
  const date = new Date(dateStr);
  return months[date.getMonth()];
}

export function getDayName(dateStr = Date.now()) {
  const date = new Date(dateStr);
  return days[date.getDay()];
}

// function getRelativeDateByDay(dateStr = Date.now()) {
//   const date = new Date(dateStr);

//   const msPerMinute = 60 * 1000;
//   const msPerHour = msPerMinute * 60;
//   const msPerQuarterDay = msPerHour * 6;

//   const elapsed = Date.now() - date.getTime();

//   if (elapsed < msPerMinute) {
//     return 'Just Now';
//   }
//   if (elapsed < msPerHour) {
//     const minutes = Math.round(elapsed / msPerMinute);
//     return `${minutes} min${minutes > 1 ? 's' : ''}`;
//   }
//   if (elapsed < msPerQuarterDay) {
//     const hours = Math.round(elapsed / msPerHour);
//     return `${hours} hour${hours > 1 ? 's' : ''}`;
//   }

//   const daysAgo = diffDays(date);

//   if (daysAgo === 0) {
//     return 'Today';
//   }
//   if (daysAgo === 1) {
//     return 'Yesterday';
//   }
//   if (daysAgo < 7) {
//     return getDayName(dateStr);
//   }
//   return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear().toString().substr(-2)}`;
// }

function getDayTimeOrDate(dateStr = Date.now()) {
  const date = new Date(dateStr);
  const dateStart = (new Date(date)).setHours(0,0,0,0);
  const todayStart = new Date().setHours(0,0,0,0);

  if (dateStart == todayStart) {
    const localDate = date.toLocaleTimeString();
    const localDateTimes = localDate.split(':');
    const localDateAMPM = localDate.split(' ')[1];
    return `${localDateTimes[0]}:${localDateTimes[1]} ${localDateAMPM}`;
  } else {
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear().toString().substr(-2)}`;
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const times = document.body.querySelectorAll('time.timeago');
  times.forEach(t => {
    const dateStr = t.getAttribute('datetime');
    t.innerText = getDayTimeOrDate(dateStr);
  });
});
