import selectAncestor from './select-ancestor';

document.addEventListener('DOMContentLoaded', () => {
  document.body.addEventListener('click', (e) => {
    const target = selectAncestor(e.target, '.school-course-modal__add-btn');
    if (!!target) {
      const btnItem = target.parentNode;
      const container = btnItem.parentNode;

      const item = document.createElement('div');

      const index = container.querySelectorAll('.school-course-section').length;
      item.classList.add('school-course-section');
      item.innerHTML = `
        <div class="compensation-section margin-bottom--lg">
          <div class="compensation-title compensation-title--no-margin ">
            <div>
              <label class="checkbox">
                <input name="[new_courses][][enabled]" type="hidden" value="0">
                <input class="compensation-checkbox" type="checkbox" checked value="1" name="[new_courses][][enabled]" id="_${index}_enabled">
                <i class="icon-checkbox checkbox__checked"></i>
                <i class="icon-checkbox-blank checkbox__blank"></i>
              </label>
              <strong>Section ${index + 1}</strong>
            </div>
          </div>

          <div class="form__field">
            <input value="" class="form__input" type="text" name="[new_courses][][label]" id="_${index}_label">
          </div>

          <div class="form__field  ">
            <label class="form__label margin-top--sm" for="_${index}_description">Details</label>
            <textarea class="form__textarea" name="[new_courses][][description]" id="_${index}_description"></textarea>
          </div>

          <div id="school-courses-${index}-error" class="error--inline"></div>
        </div>
      `;
      container.insertBefore(item, btnItem);
    }
  }, true);
});
