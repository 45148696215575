/**
 * <div data-segmented-control="group_name" data-segmented-control-default-value="default_view">
 *  <button data-segmented-control-button="default_view">Default View</button>
 * </div>
 * <div data-segmented-control-view-group="group_name" data-segmented-control-view="default_view">Hello World</div>
 */

import selectAncestor from './select-ancestor';

document.addEventListener('DOMContentLoaded', () => {

  // initialize
  [...document.body.querySelectorAll('[data-segmented-control]')]
    .forEach(ele => {
      const segmentedControlName = ele.getAttribute('data-segmented-control');

      const buttonElements = [...ele.querySelectorAll('[data-segmented-control-button]')];
      const viewElements = [...document.body.querySelectorAll(`[data-segmented-control-view-group=${segmentedControlName}]`)];

      const hash = window.location.hash.slice(1);
      const parsedHash = new URLSearchParams(hash);
      const defaultState = (parsedHash.get('segment') || ele.getAttribute('data-segmented-control-default-value')) ?? buttonElements[0].getAttribute('data-segmented-control-button');

      buttonElements.forEach(buttonEle => {
        const viewValue = buttonEle.getAttribute('data-segmented-control-button');
        if (viewValue === defaultState) {
          buttonEle.classList.add('segmented-control__button--selected');
        }
      });
      viewElements.forEach(viewEle => {
        const viewValue = viewEle.getAttribute('data-segmented-control-view');
        if (viewValue === defaultState) {
          viewEle.classList.add('segmented-control-view--show');
        }
      });
    }
  );

  document.body.addEventListener('click', (e) => {
    if (e.target.hasAttribute('data-segmented-control-button')) {
      const container = selectAncestor(e.target, '[data-segmented-control]');
      const segmentedControlName = container.getAttribute('data-segmented-control');

      const buttonValue = e.target.getAttribute('data-segmented-control-button')
      if (buttonValue) {
        const buttonElements = container.querySelectorAll('[data-segmented-control-button]');
        const viewGroupElements = document.body.querySelectorAll(`[data-segmented-control-view-group=${segmentedControlName}]`);

        // cycle linked views. remove and add classes to selecte appropriate button
        [...buttonElements].forEach(buttonEle => {
          if (buttonEle && buttonEle.getAttribute('data-segmented-control-button') === buttonValue) {
            buttonEle.classList.add('segmented-control__button--selected');
            window.history.replaceState(null, '', `${document.location.pathname}#segment=${buttonValue}`);
          } else {
            buttonEle.classList.remove('segmented-control__button--selected');
          }
        });

        // cycle linked views. remove and add classes to show appropriate view
        [...viewGroupElements].forEach(viewGroupEle => {
          if (viewGroupEle) {
            if (
              viewGroupEle.hasAttribute('data-segmented-control-view') &&
              viewGroupEle.getAttribute('data-segmented-control-view') === buttonValue
            ) {
              viewGroupEle.classList.add('segmented-control-view--show');
            } else {
              viewGroupEle.classList.remove('segmented-control-view--show');
            }
          }
        });
      }
    }
  }, true);
});
