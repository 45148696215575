document.addEventListener('DOMContentLoaded', () => {
  function handleRedirect(e, type) {
    if (e.target.hasAttribute(`data-redirect-${type}`)) {
      const url = e.target.getAttribute(`data-redirect-${type}`);
      window.location.replace(url);
    }
  }

  document.body.addEventListener('click', e => handleRedirect(e, 'onclick'), true);
  document.body.addEventListener('change', e => handleRedirect(e, 'onchange'), true);
});
