const { stringExists } = require("../validators")

document.addEventListener('DOMContentLoaded', () => {
  if (!!document.querySelector('body.top_shop_profiles.show') && !!document.querySelector('#topshop-about-us-form')) {

    const form = document.getElementById('topshop-about-us-form');
    const submit = form.querySelector('input[type="submit"]');

    let state = document.getElementById('shop_about_us').value;

    submit.disabled = submit.disabled || !stringExists(state);
    form.addEventListener('input', (event) => {
      state = event.target.value;
      submit.disabled = !stringExists(state);
    });

  }
});
