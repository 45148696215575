import axios from 'axios';

document.addEventListener('DOMContentLoaded', () => {
  let holdTimer;
  let initialX = 0;
  let initialY = 0;
  let offsetX = 0;
  let offsetY = 0;
  let activeTarget = null;
  let activeIndex = null;
  let selectedIndex = null;

  let items = [];

  function setDisplayOrder(url, value) {
    const csrf = document.querySelector('meta[name=csrf-token]').getAttribute('content');
    const headers = {
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': csrf,
    };

    axios.post(url, {
      display_orders: value,
    }, {
      headers,
    })
    .then(res => console.log(res.data))
    .catch(res => {
      if (res instanceof Error) {
        console.error(res.message);
      } else {
        console.error(res.data);
      }
    });
  }

  function isBound(item, event) {
    if (event.target === item) return false;

    const rect = item.getBoundingClientRect();

    if (event.type === 'touchmove') {
      return rect.left <= event.touches[0].clientX && rect.right >= event.touches[0].clientX && rect.top <= event.touches[0].clientY && rect.bottom >= event.touches[0].clientY;
    } else {
      return rect.left <= event.clientX && rect.right >= event.clientX && rect.top <= event.clientY && rect.bottom >= event.clientY;
    }
  }

  function dragStart(e) {
    if (e.target.hasAttribute('data-draggable')) {
      if (holdTimer) clearTimeout(holdTimer);

      if (!activeTarget) {
        holdTimer = setTimeout(() => {
          const appContainer = document.querySelector('body > .app-container');
          appContainer.style.overflow = 'auto';

          e.target.style.zIndex = '100';
          e.target.style.boxShadow = 'rgba(0, 0, 0, 0.20) 0 4px 16px';
          e.target.style.opacity = '0.75';
          e.target.style.cursor = 'grabbing';

          if (e.type === 'touchstart') {
            initialX = e.touches[0].clientX;
            initialY = e.touches[0].clientY;
          } else {
            initialX = e.clientX;
            initialY = e.clientY;
          }

          activeTarget = e.target;

          const container = e.target.closest('[data-draggable-container]');
          items = Array.from(container.querySelectorAll('[data-draggable]'));

          activeIndex = items.indexOf(e.target);
        }, 250);
      }
    }
  }

  function drag(e) {
    if (e.target.hasAttribute('data-draggable')) {
      if (e.target === activeTarget) {
        clearTimeout(holdTimer);

        if (e.type === 'touchmove') {
          offsetX = e.touches[0].clientX - initialX;
          offsetY = e.touches[0].clientY - initialY;
        } else {
          offsetX = e.clientX - initialX;
          offsetY = e.clientY - initialY;
        }

        let didSelect = false;
        for (let i = 0; i < items.length; i++) {
          if (isBound(items[i], e)) {
            didSelect = true;
            selectedIndex = i;

            if (activeIndex > selectedIndex) {
              items[i].querySelector('.grid-item__select-bar.grid-item__select-bar--left').style.display = 'block';
            } else if (activeIndex < selectedIndex) {
              items[i].querySelector('.grid-item__select-bar.grid-item__select-bar--right').style.display = 'block';
            }
          } else {
            items[i].querySelector('.grid-item__select-bar.grid-item__select-bar--left').style.display = null;
            items[i].querySelector('.grid-item__select-bar.grid-item__select-bar--right').style.display = null;
          }
        }

        if (!didSelect) {
          selectedIndex = null;
        }

        e.target.style.transform = `translate3d(${offsetX}px, ${offsetY}px, 0)`;
      }
    }
  }

  function dragEnd(e) {
    if (e.target.hasAttribute('data-draggable')) {
      const appContainer = document.querySelector('body > .app-container');
      appContainer.style.overflow = 'auto';

      if (holdTimer) clearTimeout(holdTimer);

      if (e.type !== 'mouseleave' && selectedIndex !== null) {
        const container = e.target.closest('[data-draggable-container]');
        const itemBuffer = container.hasAttribute('data-draggable-item-buffer') ? parseInt(container.getAttribute('data-draggable-item-buffer'), 10) : 1;
        const activeItem = container.children.item(activeIndex + itemBuffer);
        const selectedItem = container.children.item(selectedIndex + itemBuffer);

        if (activeIndex > selectedIndex) {
          selectedItem.insertAdjacentElement('beforebegin', activeItem);
        } else if (activeIndex < selectedIndex) {
          selectedItem.insertAdjacentElement('afterend', activeItem);
        }

        if (activeIndex !== selectedIndex) {
          const items = Array.from(container.querySelectorAll('[data-draggable]'));
          const value = items.map((item, i) => ({
            id: item.getAttribute('data-draggable'),
            display_order: i,
          }));

          const url = container.getAttribute('data-draggable-container');

          setDisplayOrder(url, value);
        }
      }

      for (let i = 0; i < items.length; i++) {
        items[i].querySelector('.grid-item__select-bar.grid-item__select-bar--left').style.display = null;
        items[i].querySelector('.grid-item__select-bar.grid-item__select-bar--right').style.display = null;
      }

      e.target.style.zIndex = null;
      e.target.style.boxShadow = null;
      e.target.style.opacity = null;
      e.target.style.cursor = null;
      e.target.style.transform = null;

      offsetX = 0;
      offsetY = 0;
      initialX = 0;
      initialY = 0;

      activeTarget = null;
      selectedIndex = null;
    }
  }

  document.querySelector('body').addEventListener('touchstart', dragStart, true);
  document.querySelector('body').addEventListener('touchend', dragEnd, true);
  document.querySelector('body').addEventListener('touchmove', drag, true);
  document.querySelector('body').addEventListener('mousedown', dragStart, true);
  document.querySelector('body').addEventListener('mouseup', dragEnd, true);
  document.querySelector('body').addEventListener('mousemove', drag, true);
  document.querySelector('body').addEventListener('mouseleave', dragEnd, true);
});
