import { get } from 'axios';
import selectAncestor from './select-ancestor';
import serializeArray from './serialize-array';

const support = (() => {
	if (!window.DOMParser) return false;
	const parser = new DOMParser();
	try {
		parser.parseFromString('x', 'text/html');
	} catch(err) {
		return false;
	}
	return true;
})();

const stringToHTML = (str) => {
	if (support) {
		const parser = new DOMParser();
		const doc = parser.parseFromString(str, 'text/html');
		return doc;
	}
	const dom = document.createElement('div');
	dom.innerHTML = str;
	return dom;
}

document.addEventListener('DOMContentLoaded', () => {
  const betterModalContainer = document.getElementById('modal');
  const modalContainer = document.getElementById('modal-container');
  const localModalsContainer = document.getElementById('local-modals-container');

  let modalState = {}; // store form data

  if (modalContainer) {
    modalContainer.addEventListener('ajax:success', (e) => {
      const [data, _, xhr] = e.detail;
      const url = xhr.getResponseHeader('Location');
      if (url) {
        window.location = url;
      } else if (data && data.querySelector) {
        const modal = data.querySelector('body').innerHTML;
        modalContainer.innerHTML = modal;
      }
    }, false);
  }

  if (betterModalContainer) {
    betterModalContainer.addEventListener('ajax:success', (e) => {
      const [data, _, xhr] = e.detail;
      const url = xhr.getResponseHeader('Location');
      if (url) {
        window.location = url;
      } else if (data && data.querySelector) {
        const modal = data.querySelector('body').innerHTML;
        betterModalContainer.innerHTML = modal;
      }
    }, false);
  }

  const setAppScroll = (enable) => {
    const appContainer = document.querySelector('body');
    if (!!appContainer) {
      if (enable) {
        appContainer.style.overflow = 'auto';
      } else {
        appContainer.style.overflow = 'hidden';
      }
    }
  }

  const onDidPresent = (elem) => {
    if (elem && elem.hasAttribute('data-modal-did-present')) {
      if (elem.hasAttribute('data-modal-did-present-params')) {
        window[elem.getAttribute('data-modal-did-present')](elem.getAttribute('data-modal-did-present-params'));
      } else {
        window[elem.getAttribute('data-modal-did-present')]();
      }
    }
    else if (elem && elem.querySelector('.modal')?.hasAttribute('data-modal-did-present')) {
      const nestedElem = elem.querySelector('.modal');
      if (nestedElem.hasAttribute('data-modal-did-present-params')) {
        window[nestedElem.getAttribute('data-modal-did-present')](nestedElem.getAttribute('data-modal-did-present-params'));
      } else {
        window[nestedElem.getAttribute('data-modal-did-present')]();
      }
    }
  }

  const onDidDissmiss = (elem) => {
    if (elem && elem.hasAttribute('data-modal-did-dismiss')) {
      if (elem.hasAttribute('data-modal-did-dismiss-params')) {
        window[elem.getAttribute('data-modal-did-dismiss')](elem.getAttribute('data-modal-did-dismiss-params'));
      } else {
        window[elem.getAttribute('data-modal-did-dismiss')]();
      }
    }
    else if (elem && elem.querySelector('.modal')?.hasAttribute('data-modal-did-dismiss')) {
      const nestedElem = elem.querySelector('.modal');
      if (nestedElem.hasAttribute('data-modal-did-dismiss-params')) {
        window[nestedElem.getAttribute('data-modal-did-dismiss')](nestedElem.getAttribute('data-modal-did-dismiss-params'));
      } else {
        window[nestedElem.getAttribute('data-modal-did-dismiss')]?.();
      }
    }
  }

  const transferAttributes = (linkElem, modalElem) => {
    if (linkElem && modalElem) {
      if (linkElem.hasAttribute('data-modal-update-url')) {
        modalElem.setAttribute('data-modal-update-url', linkElem.getAttribute('data-modal-update-url'));
      }
      if (linkElem.hasAttribute('data-modal-did-present')) {
        modalElem.setAttribute('data-modal-did-present', linkElem.getAttribute('data-modal-did-present'));
      }
      if (linkElem.hasAttribute('data-modal-did-present-params')) {
        modalElem.setAttribute('data-modal-did-present-params', linkElem.getAttribute('data-modal-did-present-params'));
      }
      if (linkElem.hasAttribute('data-modal-did-dismiss')) {
        modalElem.setAttribute('data-modal-did-dismiss', linkElem.getAttribute('data-modal-did-dismiss'));
      }
      if (linkElem.hasAttribute('data-modal-did-dismiss-params')) {
        modalElem.setAttribute('data-modal-did-dismiss-params', linkElem.getAttribute('data-modal-did-dismiss-params'));
      }
    }
  }

  const revertUrl = (elem) => {
    if (elem && elem.hasAttribute('data-modal-update-url')) {
      const defaultUrl = elem.getAttribute('data-modal-update-url');
      if (!!defaultUrl) history.pushState(null, '', defaultUrl);
    }
  }

  const hideAllLocalModals = () => {
    [...localModalsContainer.children].forEach((elem) => {
      if (elem.classList.contains('modal-container--show')) {
        onDidDissmiss(elem);
        elem.classList.remove('modal-container--show');
        revertUrl(elem);
      }
    });
  }

  document.body.addEventListener('click', (e) => {
    /* handle [data-modal] */
    const modalTarget = selectAncestor(e.target, '[data-modal]');
    if (!!modalTarget) {

      e.preventDefault();
      hideAllLocalModals();

      const location = modalTarget.getAttribute('href');
      const headers = {
        'Accept': modalTarget.hasAttribute('data-modal-remote') ? 'text/javascript, application/javascript, application/ecmascript, application/x-ecmascript, */*' : 'text/html',
        'X-Requested-With': 'XMLHttpRequest',
      };

      get(location, { headers }).then((response) => {
        modalContainer.innerHTML = response.data;

        setAppScroll(false);

        // persist data if form exists
        const form = modalContainer.querySelector('form');
        if (form && form.action) {
          const formData = modalState[form.action] || [];
          formData.forEach((inputData) => {
            form.elements[inputData.name].value = inputData.value;
          });
        }
      }).then(() => {
        if (modalTarget.hasAttribute('data-modal-update-url')) {
          history.pushState(null, '', location);
        }
        else if (modalTarget.querySelector('.modal')?.hasAttribute('data-modal-update-url')) {
          history.pushState(null, '', location);
        }

        onDidPresent(modalTarget);
        transferAttributes(modalTarget, modalContainer.firstElementChild);
      });
    }

    /* handle [data-better-modal] */
    const betterModalTarget = selectAncestor(e.target, '[data-better-modal]');
    if (!!betterModalTarget) {

      e.preventDefault();
      hideAllLocalModals();

      const location = betterModalTarget.getAttribute('href');
      const headers = {
        'Accept': 'text/html',
        'X-Requested-With': 'XMLHttpRequest',
      };

      get(location, { headers }).then((response) => {
        betterModalContainer.append(stringToHTML(response.data).getElementById('modal').firstElementChild);

        setAppScroll(false);

        // persist data if form exists
        const form = betterModalContainer.querySelector('form');
        if (form && form.action) {
          const formData = modalState[form.action] || [];
          formData.forEach((inputData) => {
            form.elements[inputData.name].value = inputData.value;
          });
        }
      }).then(() => {
        if (betterModalTarget.hasAttribute('data-modal-update-url')) {
          history.pushState(null, '', location);
        }

        onDidPresent(betterModalTarget);
        transferAttributes(betterModalTarget, betterModalContainer.firstElementChild);
      });
    }

    /* handle [data-local-modal] */
    const localModalTarget = selectAncestor(e.target, '[data-local-modal]');
    if (!!localModalTarget) {

      const modalId = localModalTarget.getAttribute('data-local-modal');
      const modal = localModalsContainer.querySelector(`#${modalId}`);

      if (!localModalTarget.hasAttribute('data-local-modal-prevent-default')) {
        hideAllLocalModals();
      }

      // show selected modal
      if (modal && !modal.classList.contains('modal-container--show')) {
        modal.classList.add('modal-container--show');
        setAppScroll(false);
        transferAttributes(modalTarget, modal);
      }
    }
  });

  if (localModalsContainer) {
    // watch local close modal links ([data-close-modal])
    [...localModalsContainer.children].forEach((elem) => {
      elem.addEventListener('click', (e) => {
        if (e.target && (
          e.target.getAttribute('data-close-modal') !== null
          || e.target.parentElement.getAttribute('data-close-modal') !== null
        )) {
          setAppScroll(true);
          elem.classList.remove('modal-container--show');

          onDidDissmiss(elem);
          revertUrl(elem);
        }

        const hideLocalModalsTarget = selectAncestor(e.target, '[data-hide-local-modals]');
        if (!!hideLocalModalsTarget) {
          if (modal && !modal.classList.contains('modal-container--show')) {
            setAppScroll(true);
          }
          hideAllLocalModals();
        }
      }, false);
    });
  }

  const registerLocalModal = (elem) => {
    elem.addEventListener('click', (e) => {
      if (e.target && (
        e.target.getAttribute('data-close-modal') !== null
        || e.target.parentElement.getAttribute('data-close-modal') !== null
      )) {
        setAppScroll(true);
        elem.classList.remove('modal-container--show');

        onDidDissmiss(elem);
        revertUrl(elem);
      }

      const hideLocalModalsTarget = selectAncestor(e.target, '[data-hide-local-modals]');
      if (!!hideLocalModalsTarget) {
        if (modal && !modal.classList.contains('modal-container--show')) {
          setAppScroll(true);
        }
        hideAllLocalModals();
      }
    }, false);
  }
  window.registerLocalModal = registerLocalModal;

  const killModals = () => {
    if (modalContainer.firstElementChild) {
      const modal = modalContainer.firstElementChild;

      onDidDissmiss(modal);
      revertUrl(modal);

      modalContainer.removeChild(modal);
    } else if (betterModalContainer.firstElementChild) {
      const modal = betterModalContainer.firstElementChild;

      onDidDissmiss(modal);
      revertUrl(modal);

      betterModalContainer.removeChild(modal);
    } else {
      hideAllLocalModals();
    }
    setAppScroll(true);
  }

  document.body.addEventListener('mousedown', (e) => {
    /* handle [data-close-modal] */
    const closeModalTarget = selectAncestor(e.target, '[data-close-modal]');
    if ((!!closeModalTarget && !closeModalTarget.classList.contains('modal-container__modal-display')) || (!!closeModalTarget && closeModalTarget.classList.contains('modal-container__modal-display') && closeModalTarget.scrollWidth > 0 && closeModalTarget.scrollWidth > e.clientX)) {

      // set form state if present
      const form = modalContainer.querySelector('form');
      if (form && form.action) {
        modalState = {
          ...modalState,
          [form.action]: serializeArray(form),
        };
      }

      if (modalContainer.firstElementChild) {
        const modal = modalContainer.firstElementChild;

        onDidDissmiss(modal);
        revertUrl(modal);

        modalContainer.removeChild(modal);
        setAppScroll(true);
      }

      if (betterModalContainer.firstElementChild) {
        const modal = betterModalContainer.firstElementChild;

        onDidDissmiss(modal);
        revertUrl(modal);

        betterModalContainer.removeChild(modal);
        setAppScroll(true);
      }
    }

    /* handle [data-close-better-modal] */
    const closeBetterModalTarget = selectAncestor(e.target, '[data-close-better-modal]');
    if (!!closeBetterModalTarget) {
      if (betterModalContainer.lastElementChild) {
        const modal = betterModalContainer.lastElementChild;

        onDidDissmiss(modal);
        revertUrl(modal);

        betterModalContainer.removeChild(modal);
      }
    }
  });

  /* handle esc key */
  document.addEventListener('keyup', (e) => {
    if (e.key === 'Escape') {
      killModals();
    }
  });

  /* handle back button */
  window.addEventListener('popstate', () => {
    killModals();
  });
});
