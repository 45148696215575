document.addEventListener('DOMContentLoaded', () => {
  document.querySelector('body').addEventListener('change', (e) => {
    if (e.target.id === 'topshop-profile-banner-upload') {
      const reader = new FileReader();

      reader.onload = function (e) {
        const uploadContainer = document.getElementById('topshop-profile-banner-preview');
        const upload = uploadContainer.querySelector('img');

        if (!upload) {
          const image = document.createElement('img');
          image.setAttribute('src', e.target.result);
          image.classList.add('width--full');
          uploadContainer.appendChild(image);
        } else {
          upload.src = e.target.result;
        }
      }
      reader.readAsDataURL(e.target.files[0]);
    }
  }, true);
});
