document.addEventListener('DOMContentLoaded', () => {
  if (!!document.querySelector('body.top_shop_profiles.show') || !!document.querySelector('body.schools.show')) {
    let defaultParamValue = '';
    if (!!document.querySelector('body.top_shop_profiles.show')) {
      const shopCategorySelect = document.getElementById('under-the-hood-select');
      defaultParamValue = shopCategorySelect ? shopCategorySelect.getAttribute('data-default-select') : 'compensation';
    } else if (!!document.querySelector('body.schools.show')) {
      const schoolCategorySelect = document.getElementById('under-the-hood-select');
      defaultParamValue = schoolCategorySelect ? schoolCategorySelect.getAttribute('data-default-select') : 'instructors';
    }

    const underTheHoodCategoryChange = (event) => {
      const selectedId = event.target.value;
      const sections = document.querySelectorAll('.topshops-faq-section');

      sections.forEach(section => section.classList.remove('topshops-faq-section--show'));
      document.getElementById(selectedId).classList.add('topshops-faq-section--show');
      document.getElementById(`${selectedId}-button`).classList.add('topshops-faq-section--show');
      document.getElementById(`${selectedId}-header-button`).classList.add('topshops-faq-section--show');

      const hash = window.location.hash;
      const urlParams = (new URL(document.location)).searchParams.toString();
      window.history.replaceState(null, '', `${document.location.pathname}?${urlParams}${hash.includes('segment=') ? `${hash.split('&')[0]}&` : '#'}faq=${selectedId.replace('topshops-faq-', '')}`);
    };

    const select = document.getElementById('under-the-hood-select');
    if(!!select){
      select.addEventListener('change', underTheHoodCategoryChange);
      document.querySelectorAll('.under-the-hood-category-btn').forEach(
        ele => ele.addEventListener('change', underTheHoodCategoryChange)
      );
    }

    // initialize
    const hash = window.location.hash.slice(1);
    const parsedHash = new URLSearchParams(hash);
    const faqParamValue = parsedHash.get('faq') || defaultParamValue;

    const faqSection = document.getElementById(`topshops-faq-${faqParamValue}`);

    if (!!faqSection) {
      const categoryBtn = document.getElementById(`under-the-hood-categories-${faqParamValue}`);
      if (!!categoryBtn){
        categoryBtn.checked = true;
        select.value = `topshops-faq-${faqParamValue}`;
        underTheHoodCategoryChange({ target: { value: `topshops-faq-${faqParamValue}` } });
      }
    }

  }
});
