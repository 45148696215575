const { stringExists } = require("./validators")

function handleUserNameForm(element) {
  const form = element.querySelector('#update-user-modal');
  const submit = form.querySelector('input[type="submit"]');

  const ids = [
    'user_first_name',
    'user_last_name',
  ];

  let state = {};
  ids.forEach(id => state = {
    ...state,
    [id]: document.getElementById(id).value,
  });

  const handleSubmitButton = () => {
    submit.disabled = !(
      stringExists(state['user_first_name'])
      && stringExists(state['user_last_name'])
    );
  }

  handleSubmitButton();
  form.addEventListener('input', (event) => {
    const { id, value } = event.target;
    state = { ...state, [id]: value };
    handleSubmitButton();
  });
}

function handlePasswordForm(element) {
  const form = element.querySelector('#update-user-password-modal');
  const submit = form.querySelector('input[type="submit"]');

  const ids = [
    'user_current_password',
    'user_password',
    'user_password_confirmation',
  ];

  let state = {};
  ids.forEach(id => state = {
    ...state,
    [id]: document.getElementById(id).value,
  });

  const handleSubmitButton = () => {
    submit.disabled = !(
      stringExists(state['user_current_password'])
      && stringExists(state['user_password'])
      && stringExists(state['user_password_confirmation'])
    );
  }

  handleSubmitButton();
  form.addEventListener('input', (event) => {
    const { id, value } = event.target;
    state = { ...state, [id]: value };
    handleSubmitButton();
  });
}

document.addEventListener('DOMContentLoaded', () => {
  const modal = document.getElementById('myshop_profile_modal');

  if (!!modal) {
    handleUserNameForm(modal);
    handlePasswordForm(modal);
  }
});
