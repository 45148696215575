export function stringExists(value = '') {
  return value.length > 0;
}

export function numberExists(value = '0') {
  return parseInt(value, 10) > 0;
}

export function isValidZipCode(value = '') {
  return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value);
}
