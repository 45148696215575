// 🍝 this requires ajax.js to be loaded before this file is used

document.addEventListener('DOMContentLoaded', () => {
  // helper to create or update a hidden input within a form
  const createHiddenInput = (form, name, value) => {
    let input = form.querySelector(`input[name="${name}"]`);
    if (!input) {
      input = document.createElement('input');
      input.type = 'hidden';
      input.name = name;
    }
    input.value = value;
    form.appendChild(input);
    return input;
  };

  // extract URLs from text using regex
  const extractLinks = (text) => {
    const regex = /(?:^|\s)(https?:\/\/\S+)(?=\s|$)/ig;
    return text.match(regex) || [];
  };

  // debounced function to scrape OG data
  const scrapeOgData = window.debounce((url) => {
    window.ajax({
      type: 'GET',
      url: '/scrape_og_data',
      data: `url=${encodeURIComponent(url)}`,
      dataType: 'script',
    });
  }, 600);

  function initializeOgDataScraper(textarea) {
    let state = {
      currentLink: null,
      blacklist: new Set(),
    };

    const form = textarea.closest('form');
    const multiMediaTypeImage = form.querySelector('.multi_media_type_image');
    const multiMediaTypeVideo = form.querySelector('.multi_media_type_video');
    const multiMediaImageFileInput = form.querySelector('.multi_media_image_file_input');
    const multiMediaVideoFileInput = form.querySelector('.multi_media_video_file_input');

    state.currentLink = form.querySelector('input[name="external_site_detail[url]"]')?.value ?? null;

    textarea.addEventListener('input', () => {
      // dont scrape if we already have media being submitted
      if (multiMediaTypeImage && multiMediaTypeImage.checked && multiMediaImageFileInput && multiMediaImageFileInput.files.length) return;
      if (multiMediaTypeVideo && multiMediaTypeVideo.checked && multiMediaVideoFileInput && multiMediaVideoFileInput.files.length) return;

      const allLinks = extractLinks(textarea.value);
      const links = allLinks.filter(link => !state.blacklist.has(link));

      console.log(state.currentLink)
      console.log(links)

      // if there are no links, clear the OG data
      if (!links.length) {
        if (!!state.currentLink) window.multiMediaClearOgData(false);
        return;
      }

      // determine if current link still exists in the textarea
      const link = (links.find(link => link.trim() === state.currentLink) || links[0]).trim();

      // if the link hasn't changed, skip processing
      if (link === state.currentLink) return;

      // update state and scrape new link
      state.currentLink = link;
      scrapeOgData(link);
    });

    window.multiMediaClearOgData = (markForBlacklist = true) => {
      if (markForBlacklist && !!state.currentLink) {
        state.blacklist.add(state.currentLink);
      }
      state.currentLink = null;

      const urlInput = form.querySelector('input[name="external_site_detail[url]"]');
      const ogUrlInput = form.querySelector('input[name="external_site_detail[og_url]"]');
      const ogTypeInput = form.querySelector('input[name="external_site_detail[og_type]"]');
      const ogTitleInput = form.querySelector('input[name="external_site_detail[og_title]"]');
      const ogImageInput = form.querySelector('input[name="external_site_detail[og_image]"]');
      const ogDescriptionInput = form.querySelector('input[name="external_site_detail[og_description]"]');

      const preview = form.querySelector('.og-data-scraper-preview');
      preview.innerHTML = '';

      urlInput?.remove();
      ogUrlInput?.remove();
      ogTypeInput?.remove();
      ogTitleInput?.remove();
      ogImageInput?.remove();
      ogDescriptionInput?.remove();

      form.appendChild(createHiddenInput(form, 'external_site_detail_destroy', 'true'));
    }
  }

  // initialize the scraper for each applicable textarea
  document.querySelectorAll('[data-og-data-scraper]').forEach(initializeOgDataScraper);

  // expose the function globally
  window.initializeOgDataScraper = initializeOgDataScraper;
});
