document.addEventListener('DOMContentLoaded', () => {
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  document.querySelector('body').addEventListener('change', (e) => {
    if (e.target.hasAttribute('data-file-size')) {
      const fileSize = e.target.files[0].size;
      const fileSizeId = e.target.getAttribute('data-file-size') || e.target.id;
      const fileSizeMax = e.target.getAttribute('data-file-size-max');

      const error = document.querySelector(`[data-file-size-error="${fileSizeId}"]`);
      if (fileSize < fileSizeMax) {
        if (error) {
          error.innerHTML= '';
        }
      } else {
        e.target.value = '';

        if (error) {
          error.innerHTML= `must be less than ${formatBytes(fileSizeMax, 0)}`;
        }
      }
    }
  }, true);
});
