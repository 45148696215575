import Sortable from 'sortablejs';

document.addEventListener('DOMContentLoaded', () => {
  function setDisplayOrder(url, value) {
    const csrf = document.querySelector('meta[name=csrf-token]').getAttribute('content');
    const headers = {
      'Accept': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': csrf,
    };

    axios.post(url, {
      display_orders: value,
    }, {
      headers,
    })
    .then(res => console.log(res.data))
    .catch(res => {
      if (res instanceof Error) {
        console.error(res.message);
      } else {
        console.error(res.data);
      }
    });
  }

  function initSortable() {
    const elements = document.body.querySelectorAll('[data-sortable]');
    elements.forEach(ele => {
      const isMobile = window.matchMedia("only screen and (max-width: 812px)").matches
      const sortableName = ele.hasAttribute('data-sortable-name') ? ele.getAttribute('data-sortable-name') : 'display_order';
      const isDisabled = ele.hasAttribute('data-sortable-item-disabled');

      Sortable.create(ele, {
        scroll: true,
        animation: 150,
        ghostClass: 'sortable-list__item--ghost',
        chosenClass: 'sortable-list__item--chosen',
        dragClass: 'sortable-list__item--drag',
        sort: !isDisabled,
        filter: '[data-sortable-item-disabled]',
        delay: isMobile ? 200 : 0,
        onUpdate: (e) => {
          const url = ele.getAttribute('data-sortable');
          const value = Array.from(e.to.children).map((child, i) => ({
            id: child.getAttribute('data-sortable-item'),
            [sortableName]: i,
          }));
          setDisplayOrder(url, value.filter(v => !!v.id));
        },
        onStart: () => document.body.classList.add('cursor-grabbing'),
        onEnd: () => document.body.classList.remove('cursor-grabbing'),
      });
    });
  }
  initSortable();
  window.initSortable = initSortable;
});
