import selectAncestor from './select-ancestor';

document.addEventListener('DOMContentLoaded', () => {
  document.body.addEventListener('click', (e) => {
    const target = selectAncestor(e.target, '.school-instructor-modal__add-btn');
    if (!!target) {
      const btnItem = target.parentNode;
      const container = btnItem.parentNode;

      const item = document.createElement('div');

      const index = container.querySelectorAll('.school-instructors-section').length;
      item.classList.add('school-instructors-section');
      item.innerHTML = `
        <div class="compensation-section margin-bottom">
          <div class="compensation-title compensation-title--no-margin ">
            <div>
              <label class="checkbox">
                <input name="[new_instructors][][enabled]" type="hidden" value="0">
                <input class="compensation-checkbox" type="checkbox" value="1" name="[new_instructors][][enabled]" id="_new-instructor-${index}_enabled">
                <i class="icon-checkbox checkbox__checked"></i>
                <i class="icon-checkbox-blank checkbox__blank"></i>
              </label>
              <strong>Instructor Name</strong>
            </div>
          </div>
          <div class="form__row">
            <div class="form__field">
              <input value="" placeholder="First Name" class="form__input" type="text" name="[new_instructors][][first_name]" id="_new-instructor-${index}_first_name">
            </div>
            <div class="form__field">
              <input value="" placeholder="Last Name" class="form__input" type="text" name="[new_instructors][][last_name]" id="_new-instructor-${index}_last_name">
            </div>
          </div>

          <div class="form__field">
            <label class="form__label" for="_new-instructor-${index}_biography">Instructor Bio</label>
            <textarea class="form__textarea" name="[new_instructors][][biography]" id="_new-instructor-${index}_biography"></textarea>
          </div>

          <div class="form__row margin-top">

            <div class="form__field">
              <label class="form__label form__label--inline" for="_new-instructor-${index}_photo">Upload Instructor Image</label> (Optional)
              <input accept="image/heif,image/heif-sequence,image/heic,image/heic-sequence,image/gif,image/jpeg,image/png,image/svg+xml" id="school_instructor_photo_upload-${index}" class="form__input hide" onchange="document.getElementById('school_instructor_photo_upload_name-${index}').innerText = this.files[0]?.name" type="file" name="[new_instructors][][photo]">

              <div class="form__file">
                <button type="button" onclick="document.getElementById('school_instructor_photo_upload-${index}').click();">
                  Choose File
                </button>

                <span id="school_instructor_photo_upload_name-${index}" class="text--dont-break-out">
                  <i>No file chosen</i>
                </span>
              </div>
            </div>
          </div>
        </div>
      `;
      item.querySelector(".checkbox").click()
      container.insertBefore(item, btnItem);
    }
  }, true);
});
